import React, { useState, useEffect } from 'react';
import { UserDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import ProfileFullInfo from './profile-full-info';
import { toast } from 'react-toastify';
import { isMobileVersion } from '../../../lib/soar-helper';
import { StyledButton } from '../../Shared/styled-button';
import PointsUtil from '../../NavigationBar/Points/points-util';
import moment from 'moment';

import ProfileName from './profile-name';
import UserAvatar from '../../Shared/Avatar/user-avatar';

interface ProfileHeaderProps {
    userProfile: UserDTO;
    shareURL?: string | null;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
    const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(undefined);
    const [twitterUrl, setTwitterUrl] = useState<string | undefined>(undefined);
    const [facebookUrl, setFacebookUrl] = useState<string | undefined>(undefined);
    const [instagramUrl, setInstagramUrl] = useState<string | undefined>(undefined);
    const [linkedInUrl, setLinkedInUrl] = useState<string | undefined>(undefined);
    const [username, setUserName] = useState<string | undefined>(undefined);
    const [userSoarProfileUrl, setUserSoarProfileUrl] = useState<string | undefined>(undefined);

    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

    useEffect(() => {
        setWebsiteUrl(UserHelper.validateUrl(props.userProfile.websiteUrl));
        setTwitterUrl(UserHelper.validateUrl(props.userProfile.twitterUrl));
        setFacebookUrl(UserHelper.validateUrl(props.userProfile.facebookUrl));
        setInstagramUrl(UserHelper.validateUrl(props.userProfile.instagramUrl));
        setLinkedInUrl(UserHelper.validateUrl(props.userProfile.linkedinUrl));
        setUserSoarProfileUrl(UserHelper.validateUrl(props.userProfile.userSoarProfileUrl));
    }, [props.userProfile]);

    useEffect(() => {
        setUserName(UserHelper.getDisplayUserName(props.userProfile));
    }, [props.userProfile]);

    return (
        <ProfileHeaderContainer hasProfile={UserHelper.hasProfileDetails(props.userProfile)}>
            <UserAvatar user={props.userProfile} margin="0px 0px 12px 0px" diameter={80} />
            <ProfileName
                isCommunityModerator={props.userProfile.communityModerator}
                isReputable={props.userProfile.reputable}
            >
                {username ?? 'Soar User'}
            </ProfileName>
            <ProfilePoints title={`${props.userProfile.name} has ${props.userProfile.points?.toLocaleString()} points`}>
                {PointsUtil.pointsFormatter(props.userProfile.points)} points
            </ProfilePoints>

            <ProfileBio>{props.userProfile.bio || ''}</ProfileBio>

            {UserHelper.hasProfileDetails(props.userProfile) ? (
                <React.Fragment>
                    {isMobileVersion ? (
                        <ProfileFullInfoButton onClick={() => setShowMoreInfo(!showMoreInfo)}>
                            {showMoreInfo ? 'LESS INFO' : 'MORE INFO'}
                        </ProfileFullInfoButton>
                    ) : (
                        <ProfileFullInfoLink onClick={() => setShowMoreInfo(!showMoreInfo)}>
                            {showMoreInfo ? 'Less info' : 'More info'}
                        </ProfileFullInfoLink>
                    )}
                </React.Fragment>
            ) : null}

            <ProfileMobileShowMore isOpen={showMoreInfo}>
                <ProfileFullInfo userProfile={props.userProfile} />
            </ProfileMobileShowMore>

            <ProfileShareIconsContainer>
                {UserHelper.hasShareLinks(props.userProfile) && (
                    <ProfileSocialContainer>
                        {props.userProfile && props.userProfile.twitterUrl && twitterUrl ? (
                            <a
                                href={twitterUrl}
                                title="X"
                                target="_blank"
                                onClick={() => Analytics.Event('Profile', 'X', props.userProfile.name)}
                            >
                                <ProfileSocialImage src="/assets/social-share-icons/twitter.png" />
                            </a>
                        ) : null}

                        {props.userProfile && props.userProfile.facebookUrl && facebookUrl ? (
                            <a
                                href={facebookUrl}
                                title="Facebook"
                                target="_blank"
                                onClick={() => Analytics.Event('Profile', 'Facebook', props.userProfile.name)}
                            >
                                <ProfileSocialImage src="/assets/social-share-icons/facebook.png" />
                            </a>
                        ) : null}

                        {props.userProfile && props.userProfile.instagramUrl && instagramUrl ? (
                            <a
                                href={instagramUrl}
                                title="Instagram"
                                target="_blank"
                                onClick={() => Analytics.Event('Profile', 'Instagram', props.userProfile.name)}
                            >
                                <ProfileSocialImage src="/assets/social-share-icons/instagram.png" />
                            </a>
                        ) : null}

                        {props.userProfile && props.userProfile.linkedinUrl && linkedInUrl ? (
                            <a
                                href={linkedInUrl}
                                title="LinkedIn"
                                target="_blank"
                                onClick={() => Analytics.Event('Profile', 'LinkedIn', props.userProfile.name)}
                            >
                                <ProfileSocialImage src="/assets/social-share-icons/linkedin.png" />
                            </a>
                        ) : null}

                        {props.userProfile.websiteUrl ? (
                            <a
                                href={websiteUrl}
                                title="Website"
                                target="_blank"
                                onClick={() => Analytics.Event('Profile', 'Link', props.userProfile.name)}
                            >
                                <ProfileSocialImage src="/assets/social-share-icons/website.png" />
                            </a>
                        ) : null}
                    </ProfileSocialContainer>
                )}
                <UserSoarProfile
                    href={userSoarProfileUrl}
                    title="Soar Profile"
                    onClick={() => {
                        const soarURL = `${window.location.origin}/profile/${props.userProfile.userId}`;
                        const textField = document.createElement('textarea');
                        textField.innerText = soarURL;
                        document.body.appendChild(textField);
                        textField.select();
                        document.execCommand('copy');
                        textField.remove();
                        toast.dark('Copied to clipboard');
                        Analytics.Event('Profile', 'Soar Profile', soarURL);
                    }}
                >
                    <ProfileSocialImage src="/assets/social-share-icons/icon-share.png" />
                </UserSoarProfile>
            </ProfileShareIconsContainer>
            {props.userProfile.createdAt && (
                <ProfileCreated>
                    Member Since {moment((props.userProfile.createdAt || 0) * 1000).format('D MMM YYYY')}
                </ProfileCreated>
            )}
        </ProfileHeaderContainer>
    );
};

export default ProfileHeader;

interface ProfileHeaderStyleProps {
    hasProfile: boolean;
}

const ProfileHeaderContainer = styled.div<ProfileHeaderStyleProps>`
    text-align: center;
    border-bottom: ${(props) => props.hasProfile && '1px solid rgba(255, 255, 255, 0.3)'};
    padding: 20px 0px 0px 0px;
    position: relative;
`;

const ProfilePoints = styled.p`
    text-align: center;
    color: #eed923;
    font-weight: bold;
    font-size: 0.9rem;
`;

const ProfileBio = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    flex: none;
    order: 2;
    flex-grow: 0;
    word-wrap: break-word;
`;

const ProfileSocialContainer = styled.div`
    margin: 10px 0px;

    @media only screen and (max-width: 600px) {
        margin: 0px 0px 12px 0px;
    }
`;

const ProfileShareIconsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProfileSocialImage = styled.img`
    width: 30px;
    height: 30px;
    margin: 0px 7.5px;
    pointer-events: all;

    @media only screen and (max-width: 600px) {
        width: 28px;
        height: 28px;
    }
`;

const ProfileMobileShowMore = styled(Collapse)`
    padding-bottom: 15px;

    &.collapsing {
        -webkit-transition-delay: 0.5s;
        transition-delay: 0.5s;
        transition: height 0.5s ease-in-out;
    }
`;

const ProfileCreated = styled.p`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
`;

const UserSoarProfile = styled.a`
    cursor: pointer;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        margin: 0px 0px 12px 0px;
    }
`;

const ProfileFullInfoLink = styled.div`
    color: white;
    font-size: 18px;
    text-decoration: underline;
    margin: 0px auto;
    margin-bottom: 15px;
    cursor: pointer;
    width: fit-content;
`;

const ProfileFullInfoButton = styled(StyledButton)`
    margin: 15px auto;
    width: 80%;
    pointer-events: all;
`;
