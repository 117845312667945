import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';

interface ActiveMapAttributionnProps {
    tileLayer: ListingDTO;
}

const ActiveMapAttribution = (props: ActiveMapAttributionnProps) => {
    return (
        <Container>
            <Reference>Original Map:</Reference>
            <ContentContainer>
                <Title href={`/maps/${props.tileLayer.id}`} title="Click to open this map" target="_blank">
                    {props.tileLayer.title}
                </Title>
                <SubText>by {props.tileLayer.userName || 'Soar User'}</SubText>
            </ContentContainer>
        </Container>
    );
};

export default ActiveMapAttribution;

const Container = styled.div`
    margin: 12px;
`;

const Title = styled.a`
    color: #eed926 !important;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
    pointer-events: all;
    text-decoration: underline;
`;

const Reference = styled.span`
    text-align: left;
    font-size: 14px;
    color: white;
    margin: 0 0 2px 2px;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    margin: 0;
`;
