import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import ListingHelper from '../../lib/listing-helper';
import MapViewHistoryHelper from '../../lib/map-view-history-helper';
import Analytics from '../../lib/user-analytics';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-modal';

interface SearchContentSimilarUserProps {
    onSelectItem: (item: CarouselItem) => void;
}

const SearchContentSimilarUser = (props: SearchContentSimilarUserProps) => {
    const lastUsedMap = MapViewHistoryHelper.getLastUsedDrawProjectMap();

    const [listingsByUser, setListingsByUser] = useState<CarouselItem[]>([]);

    useEffect(() => {
        if (!lastUsedMap || lastUsedMap.id === undefined) return;
        ApiListings.getSimilarUserListingsByMapId(lastUsedMap.id).then((res) => {
            const items: CarouselItem[] = res.map((t) => {
                return {
                    listingId: t.id,
                    previewUrl: ListingHelper.getPreviewUrlForListing(t.id),
                    title: t.title,
                    author: t.userName,
                    opacity: 1,
                };
            });
            setListingsByUser(items);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!lastUsedMap) return <React.Fragment />;
    if (listingsByUser.length === 0) return <React.Fragment />;

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-explore.svg" />
                Other maps by <span>{lastUsedMap.author}</span>
            </SegmentTitle>

            <CarouselContainer>
                <SearchContentCarousel
                    items={listingsByUser}
                    height="180px"
                    handleSelectItem={(item: CarouselItem) => {
                        props.onSelectItem(item);
                        Analytics.Event('Search Bar', 'Clicked Map From Similar User', item.listingId);
                    }}
                />
            </CarouselContainer>
        </SegmentContainer>
    );
};

export default SearchContentSimilarUser;

const CarouselContainer = styled.div``;
