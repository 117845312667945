import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMapBounds } from '../../store/App/selectors';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-modal';

import { DateRange, last30Days } from '../Drawer/Satellites/Sentinel/sentinel-filter';
import { SentinelMetadata } from '../../store/Map/Sentinel/model';
import { getFeatures } from '../../lib/sentinel-service';
import { PulseLoader } from '../Shared/pulse-loader';
import Constants from '../../constants';

interface SearchContentSentinelInViewportProps {
    onSelectItem: (mapLayer: CarouselItem) => void;
}

const worldBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

const SearchContentSentinelInViewport = (props: SearchContentSentinelInViewportProps) => {
    const aoi = useSelector(selectMapBounds);
    const [fetchingSentinel, setFetchingSentinel] = useState(false);
    const [sentinelFeatures, setSentinelFeatures] = useState<CarouselItem[]>([]);

    const layer: SentinelMetadata = Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR;
    const dateRange: DateRange = last30Days;

    useEffect(() => {
        const selectedAoi = aoi || worldBounds;
        if (selectedAoi && selectedAoi instanceof L.LatLngBounds) {
            setFetchingSentinel(true);
            getFeatures(selectedAoi, dateRange.startDate, dateRange.endDate, layer)
                .then((res) => {
                    const items: CarouselItem[] = res.map((t) => {
                        return {
                            previewUrl: t.previewUrl,
                            title: new Date(t.date).toLocaleDateString(),
                            author: t.satellite,
                            satelliteFeature: t,
                            opacity: 1,
                        };
                    });
                    setSentinelFeatures(items);
                })
                .catch((e) => console.log({ e })) // TODO: add action when nothing is fetched
                .finally(() => setFetchingSentinel(false));
        }
    }, [aoi, dateRange.startDate, dateRange.endDate, layer]);

    if (fetchingSentinel) {
        return (
            <SegmentContainer>
                <PulseLoader />
            </SegmentContainer>
        );
    }

    if (sentinelFeatures.length === 0) return <React.Fragment />;

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                Satellite Feeds in <span>this area</span>
            </SegmentTitle>

            <CarouselContainer>
                <SearchContentCarousel
                    items={sentinelFeatures}
                    height="180px"
                    handleSelectItem={(item: CarouselItem) => props.onSelectItem(item)}
                />
            </CarouselContainer>
        </SegmentContainer>
    );
};

export default SearchContentSentinelInViewport;

const CarouselContainer = styled.div``;
