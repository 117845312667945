import { LatLng, LatLngBounds, LatLngBoundsExpression } from 'leaflet';
import React, { useState } from 'react';
import { ImageOverlay, Polygon } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';
import SatelliteUtil from '../../../lib/satellite-util';
import { actionSentinelFetchFeaturesError } from '../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedFeatureOpacity,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../store/Map/Sentinel/selectors';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import ImageOverlayEvented from '../image-overload-evented';

const SentinelFeatures = () => {
    const sentinelSelectedAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelSelectedFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelOverlayOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sentinelError = useSelector(selectSentinelError);

    const [highResLoaded, setHighResLoaded] = useState(false);

    const dispatch = useDispatch();

    const squarePolygonForBounds = (bounds: LatLngBounds): LatLngBounds => {
        const ns = bounds.getNorth() - bounds.getSouth();
        const ew = bounds.getEast() - bounds.getWest();

        if (Math.abs(ns) > Math.abs(ew)) {
            const north = bounds.getSouth() + ns / 2 + ns / 4;
            const south = bounds.getSouth() + ns / 2 - ns / 4;
            const east = bounds.getEast();
            const west = bounds.getWest();
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        } else {
            const north = bounds.getNorth();
            const south = bounds.getSouth();
            const east = bounds.getWest() + ew / 2 + ew / 4;
            const west = bounds.getWest() + ew / 2 - ew / 4;
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        }
    };

    const handleOverlayErrorMessage = (errMessage: string, err: string) => {
        if (errMessage && sentinelSelectedFeature && highResLoaded) {
            dispatch(actionSentinelFetchFeaturesError(errMessage));
            SatelliteUtil.sendSentinelSlackMessage(
                `ERROR : ${sideDrawerMode} - FAILED TO LOAD IMAGE`,
                `TYPE: ${err}`,
                sentinelSelectedFeature.highResolutionPreviewUrl
            );
        }
    };

    return (
        <React.Fragment>
            {sentinelSelectedAOI && !sentinelSelectedFeature && !isBoxSelectActive ? (
                <React.Fragment>
                    <Polygon
                        positions={GeoUtil.polygonForBounds(sentinelSelectedAOI)}
                        weight={1}
                        interactive={false}
                        fillColor="transparent"
                        key="overlayFilledPolygon"
                    />
                </React.Fragment>
            ) : null}
            {sentinelSelectedAOI && sentinelSelectedFeature ? (
                <React.Fragment>
                    {/* Polygon once a feature has been selected */}

                    {!highResLoaded && (
                        <ImageOverlay
                            url={
                                sentinelError
                                    ? '/assets/image-unavailable/image_unavailable.png'
                                    : sentinelSelectedFeature.previewUrl
                            }
                            key={sentinelSelectedFeature.previewUrl}
                            zIndex={1}
                            interactive={false}
                            bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                            opacity={sentinelOverlayOpacity ?? 1.0}
                        />
                    )}

                    {!sentinelError ? (
                        <React.Fragment>
                            <Polygon
                                positions={GeoUtil.polygonForBounds(sentinelSelectedAOI)}
                                fillColor="transparent"
                                interactive={false}
                                weight={1}
                            />

                            <ImageOverlayEvented
                                onStart={() => {
                                    setHighResLoaded(false);
                                }}
                                onLoad={() => {
                                    setHighResLoaded(true);
                                }}
                                onError={(err: Error) => {
                                    setHighResLoaded(true);
                                    handleOverlayErrorMessage(
                                        "Sorry, this service is temporarily unavailable. We're working to get it up and running as soon as possible. Please check in again later.",
                                        err.message
                                    );
                                }}
                                key={sentinelSelectedFeature.highResolutionPreviewUrl}
                                url={sentinelSelectedFeature.highResolutionPreviewUrl}
                                zIndex={2}
                                bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                                opacity={sentinelOverlayOpacity ?? 1.0}
                            />
                        </React.Fragment>
                    ) : null}

                    {!highResLoaded && (
                        <ImageOverlay
                            url="/assets/loading.gif"
                            zIndex={20}
                            interactive={false}
                            bounds={
                                GeoUtil.polygonForBounds(
                                    squarePolygonForBounds(sentinelSelectedAOI)
                                ) as LatLngBoundsExpression
                            }
                        />
                    )}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default SentinelFeatures;
